import React from "react";
import Layout from "../areas/main/components/Layout";
import logoDark from "../areas/main/assets/images/eatella-logo.png";
import { Helmet } from "react-helmet";
import "../../static/contact/thanks.css";
import Footer from "../areas/main/components/Footer";
import Heart from "../../static/images/big-heart.png";
import Texts from "../utils/lang-utils";

const ContactPage = () => (
  <Layout
    pageTitle="EATELLA | thank you"
    description="The best app for foodies who love taking pictures of food. Transform your foodstagram content into food diary and recommendations to inspire your foodie firends"
    language="en"
  >
    <Helmet>
      <link href="/social/mobile.css" rel="stylesheet" />
    </Helmet>
    <header className="site-header site-header__header-one">
      <nav className="navbar navbar-expand-lg navbar-light header-navigation stricky">
        <div className="container clearfix">
          <div className="logo-box clearfix">
            <a className="navbar-brand" href="/">
              <img src={logoDark} className="main-logo" width="159" alt="alter text" />
            </a>
          </div>
          <div className="main-navigation">
            <ul className=" one-page-scroll-menu navigation-box">
              <li className="scrollToLink">
                <a></a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>

    <section className="banner-one app-intro" id="banner">
      <div className="container contact-container">
        <div className="row">
            <div className="thanks-container">
                <img src={Heart} alt="heart" width="120" height="120" />
                <h2 className="thanks-header">{Texts.ThankYou}</h2>
                <p>{Texts.WIllRespond}</p>
            </div>
        </div>
      </div>
    </section>
    <Footer />
  </Layout>
  
);

export default ContactPage;
